.fiveg-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.fiveg-header {
  text-align: center;
  margin-bottom: 30px;
}

.fiveg-title {
  font-size: 36px;
  font-weight: bold;
}

.fiveg-content {
  font-size: 18px;
  line-height: 1.6;
}

.fiveg-section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.fiveg-section {
  margin-bottom: 30px;
}

.fiveg-section ul {
  list-style-type: disc;
  margin-left: 20px;
}
