/* BlockChainBlog.css */

.blockchain-blog-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.blog-title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.blog-content {
  font-size: 18px;
  line-height: 1.6;
}

.blog-content h2 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.blog-content ol,
.blog-content ul {
  margin-top: 10px;
  margin-bottom: 20px;
}

.blog-content ol li,
.blog-content ul li {
  margin-bottom: 10px;
}

/* You can add more styles to customize the appearance further */
