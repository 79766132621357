/********** Template CSS **********/
:root {
  --primary: #d65152;
  --secondary: #d65152;
  --light: #fef3ee;
  --dark: #333333;
}

/*** Spinner ***/
.spinner {
  width: 40px;
  height: 40px;
  background: var(--primary);
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Heading ***/
h1,
h2,
.fw-bold {
  font-weight: 800 !important;
}

h3,
h4,
.fw-semi-bold {
  font-weight: 700 !important;
}

h5,
h6,
.fw-medium {
  font-weight: 600 !important;
}

.bg-primary {
  background-color: #d65152 !important;
}

.text-primary {
  color: #d65152 !important;
}

.btn-primary {
  background-color: #d65152;
  border-color: #d65152;
}

.bg-light {
  background-color: #fef3ee !important;
}

.btn-primary:hover {
  color: #000;
  background-color: #d65152;
  border-color: #d65152;
}

.border-primary {
  border-color: #d65152 !important;
}

a {
  color: #d65152;
}

a:hover {
  color: #d65152;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.5s;
}

.btn-primary,
.btn-secondary {
  color: #ffffff;
  box-shadow: inset 0 0 0 50px transparent;
}

.btn-primary:hover {
  box-shadow: inset 0 0 0 0 var(--primary);
}

.btn-secondary:hover {
  box-shadow: inset 0 0 0 0 var(--secondary);
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 30px;
  height: 30px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  font-family: "Nunito", sans-serif;
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
  color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar-dark .navbar-brand h1 {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
    position: relative;
    background: #ffffff;
  }

  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    color: var(--dark);
  }

  .navbar-dark .navbar-brand h1 {
    color: var(--primary);
  }
}

@media (min-width: 992px) {
  /* .serviceCardWidth{
width: 400px;
  } */
  .d-lg-block {
    display: block !important;
  }

  .navbar-dark {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(256, 256, 256, 0.1);
    z-index: 999;
  }

  .sticky-top.navbar-dark {
    position: fixed;
    background: #ffffff;
  }

  .navbar-dark .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    bottom: -1px;
    left: 50%;
    background: var(--primary);
    transition: 0.5s;
  }

  .navbar-dark .navbar-nav .nav-link:hover::before,
  .navbar-dark .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }

  .navbar-dark .navbar-nav .nav-link.nav-contact::before {
    display: none;
  }

  .sticky-top.navbar-dark .navbar-brand h1 {
    color: var(--primary);
  }
}

/*** Carousel ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(9, 30, 62, 0.7);
  z-index: 1;
}

@media (max-width: 576px) {
  /* 
  .d-sm-block {
    display: block !important;
  } */

  .serviceCardWidth {
    width: 300px;
  }

  .carousel-caption h5 {
    font-size: 14px;
    font-weight: 500 !important;
  }

  .carousel-caption h1 {
    font-size: 30px;
    font-weight: 600 !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

/*** Section Title ***/
.section-title::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 5px;
  left: 0;
  bottom: 0;
  background: var(--primary);
  border-radius: 2px;
}

.section-title.text-center::before {
  left: 50%;
  margin-left: -75px;
}

.section-title.section-title-sm::before {
  width: 90px;
  height: 3px;
}

.section-title::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 5px;
  bottom: 0px;
  background: #ffffff;
  -webkit-animation: section-title-run 5s infinite linear;
  animation: section-title-run 5s infinite linear;
}

.section-title.section-title-sm::after {
  width: 4px;
  height: 3px;
}

.section-title.text-center::after {
  -webkit-animation: section-title-run-center 5s infinite linear;
  animation: section-title-run-center 5s infinite linear;
}

.section-title.section-title-sm::after {
  -webkit-animation: section-title-run-sm 5s infinite linear;
  animation: section-title-run-sm 5s infinite linear;
}

@keyframes section-title-run {
  0% {
    left: 0;
  }

  50% {
    left: 145px;
  }

  100% {
    left: 0;
  }
}

@keyframes section-title-run-center {
  0% {
    left: 50%;
    margin-left: -75px;
  }

  50% {
    left: 50%;
    margin-left: 45px;
  }

  100% {
    left: 50%;
    margin-left: -75px;
  }
}

@keyframes section-title-run-sm {
  0% {
    left: 0;
  }

  50% {
    left: 85px;
  }

  100% {
    left: 0;
  }
}

/*** Service ***/
.service-item {
  position: relative;
  height: 300px;
  padding: 0 30px;
  transition: 0.5s;
}

.service-item .service-icon {
  width: 60px;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 2px;
}

.service-item .service-icon i {
  transform: rotate(45deg);
}

.service-item a.btn {
  position: absolute;
  width: 60px;
  bottom: -48px;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
}

.service-item:hover a.btn {
  bottom: -24px;
  opacity: 1;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dots .owl-dot span {
  width: 10px;
  height: 15px;
  margin: 0 5px;
  background-color: #ccc;
  transition: width 5s cubic-bezier(0.42, 0, 0.58, 1);
}

.owl-theme .owl-dots .owl-dot.active span:hover {
  border-radius: 0;
  padding: 10px;
}

.owl-theme .owl-dots .owl-dot.active span {
  border-radius: 2px;
  padding: 8px;
  width: 30px;
  /* Increase the width of active dot */
  background-color: var(--primary);
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--primary);
}

.owl-theme .owl-dots .owl-dot span {
  padding: 8px;
  border-radius: 2px;
}

.testimonial-carousel .owl-dots {
  position: relative;
  display: inline;
  margin: 0 5px;
  width: 15px;
  height: 0px;
  background: var(--primary);
  border-radius: 2px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  width: 30px;
  background: var(--primary);
}

.testimonial-carousel .owl-item.center {
  position: relative;
  z-index: 1;
}

.testimonial-carousel .owl-item .testimonial-item {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: #ffffff !important;
  box-shadow: 0 0 30px #dddddd;
}

/*** Team ***/
.team-item {
  transition: 0.5s;
}

.team-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.team-social a.btn {
  position: relative;
  margin: 0 3px;
  margin-top: 100px;
  opacity: 0;
}

.team-item:hover {
  box-shadow: 0 0 30px #dddddd;
}

.team-item:hover .team-social {
  background: rgba(9, 30, 62, 0.7);
}

.team-item:hover .team-social a.btn:first-child {
  opacity: 1;
  margin-top: 0;
  transition: 0.3s 0s;
}

.team-item:hover .team-social a.btn:nth-child(2) {
  opacity: 1;
  margin-top: 0;
  transition: 0.3s 0.05s;
}

.team-item:hover .team-social a.btn:nth-child(3) {
  opacity: 1;
  margin-top: 0;
  transition: 0.3s 0.1s;
}

.team-item:hover .team-social a.btn:nth-child(4) {
  opacity: 1;
  margin-top: 0;
  transition: 0.3s 0.15s;
}

.team-item .team-img img,
.blog-item .blog-img img {
  transition: 0.5s;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
  transform: scale(1.15);
}

/*** Miscellaneous ***/
@media (min-width: 991.98px) {
  .facts {
    position: relative;
    margin-top: -75px;
    z-index: 1;
  }
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/* .bg-header {
  background: linear-gradient(rgba(9, 30, 62, 0.7), rgba(9, 30, 62, 0.7)),
    url(../img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
} */

.link-animated a {
  transition: 0.5s;
}

.link-animated a:hover {
  padding-left: 10px;
}

@media (min-width: 767.98px) {
  .serviceCardWidth {
    width: 320px;
  }

  .footer-about {
    margin-bottom: -75px;
  }
}

@media (min-width: 1200px) {
  .serviceCardWidth {
    width: 400px;
  }

  .display-1 {
    font-size: 4rem;
  }
}

.description-container {
  overflow: hidden;
  /* Ensure text doesn't overflow the container */
}

.loader {
  width: 215px;
  height: 220px;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
    linear-gradient(#ddd, #ddd);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 215px 220px, 215px 130px, 100px 15px, 150px 15px;
  background-position: -215px 0, 0 0, 15px 150px, 15px 180px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 215px 0, 0 0, 15px 150px, 15px 180px;
  }
}

.artificial-intelligence-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.blog-title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Nunito", sans-serif;
}

.blog-content {
  font-size: 18px;
  line-height: 1.6;
  color: #6b6a75;
}

.future-tech-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.future-tech-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.future-tech-list {
  list-style-type: none;
  padding: 0;
}

.future-tech-item {
  margin-bottom: 20px;
}

.future-tech-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

.future-tech-link:hover {
  border-bottom-color: #007bff;
}

/* Tooltip */
.future-tech-link[title]:hover::before {
  content: attr(title);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #ff0000;
  border: 1px solid rgb(187, 10, 10);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.portfolio-container {
  display: flex;
  flex-wrap: wrap;
}

.portfolio-item {
  width: calc(50% - 20px);
  margin: 20px;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

/* .portfolio-item-info {
  padding-top: 50px;
  padding-bottom: 50px;
} */

.logo-img {
  max-width: 100px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.tags {
  margin-bottom: 20px;
}

.tag {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
}

.description {
  margin-bottom: 20px;
}

.desc-item {
  margin-right: 10px;
  font-size: 14px;
  color: #666;
}

.btn-view {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.btn-view:hover {
  background-color: #0056b3;
}

.portfolio-images {
  width: 50%;
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.desktop-image,
.mobile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}

/* .mobile-image {
  opacity: 0;
} */

/* .portfolio-item:hover .mobile-image {
  opacity: 1;
}

.portfolio-item:hover .desktop-image {
  opacity: 0;
} */
.statistics-img {
  width: 100px;
  height: 100px;
  float: left;
  padding: 15px;
  background: #fff;
  border-radius: 8px 40px 8px 40px;
  -webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
    0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
    0 25px 40px -30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
    0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
    0 25px 40px -30px rgba(0, 0, 0, 0.2);
}

.statnumb {
  margin: 0 0 0 20px;
  text-align: left;
  float: left;
}

.counter-number {
  transform: translateZ(20px);
}

img {
  vertical-align: middle;
  border-style: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-os-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  vertical-align: baseline;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio-item {
  width: 100%;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.tilt {
  overflow: hidden;
  height: 200px;
}

.tilt img {
  width: 100px;
  height: auto;
  transition: transform 0.5s ease;
}

.tilt:hover img {
  transform: scale(1.05);
}

.portfolio-item-info {
  padding: 40px;
}

.portfolio-logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.tags {
  margin-top: 10px;
}

.tags span {
  margin-right: 10px;
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
}

h3 {
  margin-top: 20px;
  font-size: 1.2rem;
}

.description-list {
  padding: 0;
}

.description-list li {
  list-style-type: none;
  margin-bottom: 5px;
}

.info-list-div div::before {
  content: "▶";
  font-size: 14px;
  margin: 0px 5px 0px 0px;
}

.info-list-span span::before {
  content: "▶";
  font-size: 14px;
  margin: 0px 5px 0px 0px;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  color: #333;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

/* .custom-prev-arrow {
  left: -40px;
} */

.custom-next-arrow {
  right: 0px;
}

.textColor {
  border-bottom: 2px solid #d65152;
  color: #d65152;

  /* color: #d65152; */
}

.textFamily {
  font-family: "Nunito", sans-serif;
}

.boxShadow {
  box-shadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px";
}

.primaryBackColor {
  background-color: #d65152;
}
